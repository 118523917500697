//import './App.css';
import React, { Component } from "react";
//import { withAuthenticator } from '@aws-amplify/ui-react'

/*
function App() {
  return (
    <div>Lord of Rent</div>
  );
}
*/

class App extends Component {
  render() {
    return <div className="flex flex-column items-center justify-left pa3 bg-light-gray">Lord of Rent</div>;
  }
}

//export default withAuthenticator(App, { includeGreetings: true });
export default App;
